const BASE_URL = process.env.REACT_APP_BASE_URL;

const API_VER = "v1/";
const MOBILE_CONFIG = "mobile-config/";

export const baseURI = BASE_URL + API_VER;
export const baseMobileConfigURI = BASE_URL + API_VER + MOBILE_CONFIG;
//login
export const LOGIN_URL = `${BASE_URL + API_VER}auth/login`;
export const LOGOUT_URL = `${BASE_URL + API_VER}auth/logout`;
export const FORGOT_PASSWORD_URL = `${BASE_URL + API_VER}auth/password/forgot`;
export const RESET_PASSWORD_URL = `${BASE_URL + API_VER}auth/password/reset`;
export const VARIFY_OTP_URL = `${BASE_URL + API_VER}auth/otp/verify`;
export const RESEND_OTP_URL = `${BASE_URL + API_VER}auth/otp/resend`;
// medical center
export const GETALLMC_URL = `${baseURI}mc/all`;
export const ADD_MEDICAL_CENTER_URL = `${baseURI}mc/create`;
export const GETMCBYID_URL = `${baseURI}mc/get`;
export const UPDATEMC_URL = `${baseURI}mc/update`;
export const DELETEMC_URL = `${baseURI}mc/delete`;
export const ACTIVEMC_URL = `${baseURI}mc/update-status`;
export const ALLMCUSERS_URL = `${baseURI}mc/all-mc-user`;
// package
export const GET_ALL_PACKAGE = `${baseURI}pkg/all`;
export const GET_ALL_PACKAGE_MODULES = `${baseURI}pkg/mdl/all`;
export const ADD_PACKAGE = `${baseURI}pkg/create`;
export const EDIT_PACKAGE = `${baseURI}pkg/update`;
export const GET_PACKAGE_BY_ID = `${baseURI}pkg/detail`;
export const DELETE_PACKAGE_BY_ID = `${baseURI}pkg/delete`;
export const UPDATE_STATUS_BY_ID = `${baseURI}pkg/update-status`;

// mobile app config - proact news
export const CREATE_NEWS_URL = `${baseMobileConfigURI}news/create`;
export const GET_NEWS_URL = `${baseMobileConfigURI}news/all`;

// mobile app config - proact medical center
export const CREATE_MEDICAL_CENTER = `${baseMobileConfigURI}mc/create`;
export const GET_MEDICAL_CENTER = `${baseMobileConfigURI}mc/all`;

// mobile app config - proact medical center
export const CREATE_DOCTORS_URL = `${baseMobileConfigURI}doctor/create`;
export const GET_DOCTORS_URL = `${baseMobileConfigURI}doctor/all`;

// medical center utility
export const GET_ALL_MEDICAL_CENTER_Utility = `${baseURI}mc/utility/mc/all`;
export const ADD_DATABASE_URI = `${baseURI}mc/utility/check/db-connection`;
export const AWS_CONNECTION = `${baseURI}mc/utility/check/aws-connection`;
export const UPDATE_CONFIG_DATA = `${baseURI}mc/utility/config/update`;

// country code
export const GET_ALL_COUNTRY = `${baseURI}country/getByFilter`;
