import React, { useState } from 'react'
import { FC } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import healthBoxLogo from '../../../assets/images/healthBoxLogo.png'
import healthBoxMainLogo from '../../../assets/images/healthBoxMainLogo.png'
import {
  HamburgerIconOpen,
  HamburgerIconClose,
} from '../../../components/common/svg-components'
import styles from './sidebar.module.scss'

import { useAppSelector } from '../../../hooks'
import { colors } from '../../../constants/color'
import { sidebarData } from '../../../constants/data'

const SideBar: FC = () => {
  const [id, setId] = useState(-1)
  const [showSidebar, setshowSidebar] = useState(false)
  const navigate = useNavigate()
  const { userData } = useAppSelector((state) => state.login)
  const handleNavigate = () => {
    userData?.role === 'SUPER_ADMIN' && navigate('/medicalcenter')
  }
  return (
    <>
      <div className={styles.sidebarContainer}>
        <div className={styles.imgToggleContainer}>
          {
            <div className={styles.logoHamburgerContainer}>
              <img
                src={healthBoxLogo}
                alt="healthbox_logo"
                className={styles.logoStyle}
                onClick={() => handleNavigate()}
              />
              <HamburgerIconOpen
                customClass={styles.toggleStyle}
                handleClick={() => setshowSidebar(!showSidebar)}
              />
            </div>
          }
        </div>
        <div className={styles.sidebarData}>
          {sidebarData?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <NavLink
                  to={item.navigate}
                  className={({ isActive }) =>
                    isActive || id === item?.id
                      ? styles.active
                      : styles.sidebarContent
                  }
                  onMouseEnter={() => setId(item?.id)}
                  onMouseLeave={() => setId(-1)}
                  children={({ isActive }) => {
                    return (
                      <>
                        <div className={styles.iconTooltip}>
                          <item.icon />
                          {/* <p className={styles.iconTooltipText}>{item.name}</p> */}
                        </div>
                      </>
                    )
                  }}
                ></NavLink>
              </React.Fragment>
            )
          })}
        </div>
      </div>
      {showSidebar && (
        <div className={styles.sidebarOverlayContainer}>
          <div className={styles.sidebarAnimatedContainer}>
            <div className={styles.imgToggleContainer}>
              {
                <div className={styles.logoHamburgerContainer}>
                  <img
                    src={healthBoxMainLogo}
                    alt="healthbox_logo"
                    className={styles.logoStyle}
                    onClick={() => handleNavigate()}
                  />
                  <HamburgerIconClose
                    customClass={styles.toggleStyle}
                    handleClick={() => setshowSidebar(!showSidebar)}
                  />
                </div>
              }
            </div>
            <div className={styles.sidebarData}>
              <hr className={styles.sidebarDivider} />
              {sidebarData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <NavLink
                      to={item.navigate}
                      className={({ isActive }) =>
                        isActive || id === item?.id
                          ? styles.active
                          : styles.sidebarContent
                      }
                      onMouseEnter={() => setId(item?.id)}
                      onMouseLeave={() => setId(-1)}
                      children={({ isActive }) => {
                        return (
                          <>
                            <div className={styles.iconTooltip}>
                              <div className={styles.iconStyle}>
                                <item.icon />
                              </div>
                              <p className={styles.iconTooltipText}>
                                {item.name}
                              </p>
                            </div>
                          </>
                        )
                      }}
                    ></NavLink>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
          <div
            className={styles.sidebarOverlay}
            onClick={() => setshowSidebar(!showSidebar)}
          />
        </div>
      )}
    </>
  )
}

export default SideBar
