// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".samplCsv_csvBtn__hMX1S {\n  border: 1px solid var(--blue1);\n  border-radius: 6px;\n  background: transparent;\n  color: var(--blue1);\n}", "",{"version":3,"sources":["webpack://./src/components/common/sample-csv/samplCsv.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".csvBtn {\n    border: 1px solid var(--blue1);\n    border-radius: 6px;\n    background: transparent;\n    color: var(--blue1);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"csvBtn": "samplCsv_csvBtn__hMX1S"
};
export default ___CSS_LOADER_EXPORT___;
