import axios from "axios";
import {
  IMPORT_DOCTORS,
  IMPORT_INSURANCE,
  IMPORT_MALAFI,
  IMPORT_MEDICINES,
} from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";

export const importCompliance = (data: IAPIPayload) => {
  return axios.post(IMPORT_MALAFI, data);
};

export const importInsurance = (data: IAPIPayload) => {
  return axios.post(IMPORT_INSURANCE, data);
};

export const importDoctors = (data: IAPIPayload) => {
  return axios.post(IMPORT_DOCTORS, data);
};
export const importMedicines = (data: IAPIPayload) => {
  return axios.post(IMPORT_MEDICINES, data);
};
