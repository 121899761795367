import {
  ACCESS_KEY,
  BUCKET_NAME,
  DATABASE_URI,
  MEDICAL_CENTER,
  REGION,
  SECRET_KEY,
} from '../constants/constant'

export interface IMedicalUtilityValidators {
  [MEDICAL_CENTER]: {
    required: string
    pattern: object
  }
  [DATABASE_URI]: {
    required: string
    pattern: object
  }
  [ACCESS_KEY]: {
    required: string
    pattern: object
  }
  [BUCKET_NAME]: {
    required: string
    pattern: object
  }
  [SECRET_KEY]: {
    required: string
    pattern: object
  }
  [REGION]: {
    required: string
    pattern: object
  }
}

export const medicalUtilityValidators = {
  [MEDICAL_CENTER]: {
    required: 'Please select medical center',
  },
  [DATABASE_URI]: {
    required: 'Please enter database url',
  },
  [ACCESS_KEY]: {
    required: 'Please enter access key ',
  },
  [BUCKET_NAME]: {
    required: 'Please enter bucket name',
  },
  [SECRET_KEY]: {
    required: 'Please enter secret key',
  },
  [REGION]: {
    required: 'Please enter bucket region',
  },
}
