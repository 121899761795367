import {
  EXPIRY_DATE,
  NAME,
  NO_OF_USER,
  PACKAGE_ID,
  PRIMARY_CONTACT_NAME,
  POC_EMAIL,
  MOBILE_NO,
  ADDRESS_LINE_1,
  COUNTRY,
  STATE,
  CITY,
  ZIP_CODE,
  DB_URI,
  BRANCH_LIMIT,
  DEPARTMENT_LIMIT,
  PATIENT_LIMIT,
  ATTACHMENT_LIMIT,
} from '../constants/constant'
import { store } from '../redux/store/store'
export const manageMedicalCenterValidators = {
  [NAME]: {
    required: 'Please enter medical center name',
  },
  [PRIMARY_CONTACT_NAME]: {
    required: 'Please enter primary contact name',
  },
  [POC_EMAIL]: {
    required: 'Please enter mc admin email',
    pattern: {
      value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/,
      message: 'Please enter a valid email address',
    },
  },
  [MOBILE_NO]: {
    required: 'Please enter mobile no',
  },
  [EXPIRY_DATE]: {
    required: 'Please select expiry date',
    validate: {
      futureDate: (value: any) => {
        const currentDate = new Date()
        const selectedDate = new Date(value)
        if (selectedDate <= currentDate) {
          return 'Expiry date must be a future date'
        }
        return true
      },
    },
  },
  [PACKAGE_ID]: {
    required: 'Please select package',
  },
  [ADDRESS_LINE_1]: {
    required: 'Please enter address line 1',
  },
  // [ADDRESS_LINE_2]: {
  //   required: "Please enter Address Line 1",
  // },
  [COUNTRY]: {
    required: 'Please enter country',
    pattern: {
      value: /^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/i,
      message: 'Please enter valid country name',
    },
  },
  [STATE]: {
    required: 'Please enter state',
    pattern: {
      value: /^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/i,
      message: 'Please enter valid state name',
    },
  },
  [CITY]: {
    required: 'Please enter city',
    pattern: {
      value: /^[a-zA-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/i,
      message: 'Please enter valid city name',
    },
  },
  [ZIP_CODE]: {
    required: 'Please enter zipcode',
    pattern: {
      value: /^\d{4,10}$/,
      message: 'Please enter valid 4 to 10 digit zipcode',
    },
  },
  // [WEBSITE]: {
  //   required: "Please enter website",
  // },

  [NO_OF_USER]: {
    required: 'Please enter number of users',
    pattern: {
      value: /^\d+$/,
      message: 'Please enter only numbers',
    },
    validate: {
      handleZero: (value: any) => {
        if (value <= 0) {
          return 'Please enter valid number of users'
        }
      },
    },
  },
  [DB_URI]: {
    required: 'Please enter db url',
  },
  [BRANCH_LIMIT]: {
    validate: {
      limitValidate: (value: any, val: any) => {
        if (val?.isBranchLimit && (value === undefined || value === '')) {
          return 'Please enter branch limit value'
        } else if (val?.isBranchLimit && Number(value) === 0) {
          return 'Please enter value greater than 0'
        } else if (val?.isBranchLimit && Number(value) < 0) {
          return 'Please enter positive value'
        }
        return true
      },
    },
  },
  [DEPARTMENT_LIMIT]: {
    validate: {
      limitValidate: (value: any, val: any) => {
        let packageList = store.getState()?.package?.packageData
        let packageSelected = packageList?.filter(
          (item: any) => item?._id == val?.package_id
        )
        let selectedModuleListRadiology =
          packageSelected?.length > 0
            ? packageSelected[0]?.module_ids?.filter(
                (data: any) => data?.name === 'Radiology'
              )
            : []
        let selectedModuleListLab =
          packageSelected?.length > 0
            ? packageSelected[0]?.module_ids?.filter(
                (data: any) => data?.name === 'Lab'
              )
            : []

        let limitNumber =
          Number(selectedModuleListRadiology?.length) +
          Number(selectedModuleListLab?.length) +
          2

        if (val?.isDepartmentLimit && (value === undefined || value === '')) {
          return 'Please enter department limit value'
        } else if (val?.isDepartmentLimit && Number(value) === 0) {
          return `Please enter value greater than 0`
        } else if (val?.isDepartmentLimit && Number(value) < limitNumber) {
          return `Value should be greater than or equal to ${limitNumber}`
        }
        return true
      },
    },
  },
  [PATIENT_LIMIT]: {
    validate: {
      limitValidate: (value: any, val: any) => {
        if (val?.isPatientLimit && (value === undefined || value === '')) {
          return 'Please enter patient limit value'
        } else if (val?.isPatientLimit && Number(value) === 0) {
          return 'Please enter value greater than 0'
        } else if (val?.isPatientLimit && Number(value) < 0) {
          return 'Please enter positive value'
        }
        return true
      },
    },
  },
  [ATTACHMENT_LIMIT]: {
    validate: {
      limitValidate: (value: any, val: any) => {
        if (val?.isAttachmentsLimit && (value === undefined || value === '')) {
          return 'Please enter attachment limit value'
        } else if (val?.isAttachmentsLimit && Number(value) === 0) {
          return 'Please enter value greater than 0'
        } else if (val?.isAttachmentsLimit && Number(value) < 0) {
          return 'Please enter positive value'
        }
        return true
      },
    },
  },
}
