// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".landingPage_mainContainer__slPY1 {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n}\n.landingPage_mainContainer__slPY1 .landingPage_formStyleContainer__ij\\+x0 {\n  width: 50%;\n  display: flex;\n  background-color: var(--white1);\n}\n.landingPage_mainContainer__slPY1 .landingPage_formStyleContainer__ij\\+x0 .landingPage_formStyle__dFFhm {\n  width: 60%;\n  margin: 30px auto;\n}\n.landingPage_mainContainer__slPY1 .landingPage_landingPageImageContainer__1zjCG {\n  width: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: rgba(8, 110, 254, 0.2);\n}", "",{"version":3,"sources":["webpack://./src/components/common/landing-page/landingPage.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AADF;AAEE;EACE,UAAA;EACA,aAAA;EACA,+BAAA;AAAJ;AACI;EACE,UAAA;EACA,iBAAA;AACN;AAEE;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kCAAA;AAAJ","sourcesContent":["@import '../../../styles//mixin.scss';\n\n.mainContainer {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  .formStyleContainer {\n    width: 50%;\n    display: flex;\n    background-color: var(--white1);\n    .formStyle {\n      width: 60%;\n      margin: 30px auto;\n    }\n  }\n  .landingPageImageContainer {\n    width: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: rgba(8, 110, 254, 0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "landingPage_mainContainer__slPY1",
	"formStyleContainer": "landingPage_formStyleContainer__ij+x0",
	"formStyle": "landingPage_formStyle__dFFhm",
	"landingPageImageContainer": "landingPage_landingPageImageContainer__1zjCG"
};
export default ___CSS_LOADER_EXPORT___;
