import { createSlice } from "@reduxjs/toolkit";
import { IImportUtility } from "../../../interfaces/apiInterface";
import { importComplianceUtility, importDoctorsUtility, importInsuranceUtility } from "./importUtilityAsynActions";

const initialState: IImportUtility = {
  isLoading: false,
};
export const importUtilitySlice = createSlice({
  name: "importUtility",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(importComplianceUtility.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(importComplianceUtility.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(importComplianceUtility.rejected, (state, error) => {
        state.isLoading = false;
      });

      builder
      .addCase(importInsuranceUtility.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(importInsuranceUtility.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(importInsuranceUtility.rejected, (state, error) => {
        state.isLoading = false;
      });

      builder
      .addCase(importDoctorsUtility.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(importDoctorsUtility.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(importDoctorsUtility.rejected, (state, error) => {
        state.isLoading = false;
      });
  },
});

export const {} = importUtilitySlice.actions;
export default importUtilitySlice.reducer;
