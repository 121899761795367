import { FC, useEffect, useState } from "react";
import AttachFiles from "../../../components/common/attach-files/Attachfiles";
import { IManageMedicalForm, fileType } from "../../../interfaces/interfaces";
import styles from "./manageMedicalCenter.module.scss";
import Button from "../../../components/common/button/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import { manageMedicalCenterValidators } from "../../../form-validators/manageMedicalCenterValidator";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
// import Select from "react-select";
import {
  EMAIL,
  NAME,
  PRIMARY_CONTACT_NAME,
  EXPIRY_DATE,
  PACKAGE_ID,
  NO_OF_USER,
  ATTACHMENTS,
  NOTES,
  MOBILE_NO,
  ADDRESS_ID,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  COUNTRY,
  STATE,
  CITY,
  ZIP_CODE,
  WEBSITE,
  POC_EMAIL,
  DB_URI,
  BRANCH_LIMIT,
  DEPARTMENT_LIMIT,
  PATIENT_LIMIT,
  ATTACHMENT_LIMIT,
  IS_BRANCH_LIMIT,
  IS_ATTACHMENT_LIMIT,
  IS_PATIENT_LIMIT,
  IS_DEPARTMENT_LIMIT,
} from "../../../constants/constant";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/index";
import {
  createMedicalCenter,
  getAllCountryList,
  getAllMedicalCenterById,
  updateMedicalCenterById,
} from "../../../redux/features/medical-center/medicalCenterAsynActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import {
  ADD_MEDICAL_CENTER_TYPE,
  UPDATE_MEDICAL_CENTER_BY_ID,
} from "../../../constants/asyncActionsType";
import { getAllPackage } from "../../../redux/features/package/packageAsyncActions";
import React from "react";
import Loader from "../../../components/common/spinner/Loader";
import { clearState } from "../../../redux/features/medical-center/medicalCenterSlice";
import { disableArrowKey, trimValue, disableScroll, utcToDate } from "../../../utils/utils";
import {
  CheckIcon,
  InfoIcon,
  UncheckIcon,
} from "../../../components/common/svg-components";
import { colors } from "../../../constants/color";
import { debounce } from "lodash";
// import { ADDRESS_LINE_2 } from "../../../constants/constant";

interface IManageMedicalCenter {}

const ManageMedicalCenter: FC<IManageMedicalCenter> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [file, setFile] = useState<any>();

  const { isLoading, medicalCenterByIdData, countryList } = useAppSelector(
    (state) => state.medicalCenter
  );
  console.log("medicalCenterByIdData", medicalCenterByIdData?._id);
  const { packageData } = useAppSelector((state) => state.package);
  // getAllPackage api call
  useEffect(() => {
    let data = {
      search: "",
      pkg: "",
      page: 1,
      pageSize: 1000,
    };
    console.log("code merge>>", requestGenerator(data));
    dispatch(getAllPackage(requestGenerator(data)));
  }, [dispatch]);

  // Create a new Date object with a UTC date string
  const utcDate = new Date(medicalCenterByIdData[EXPIRY_DATE]);
  // Convert UTC date to local date
  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
  );
  const day = localDate.getDate().toString().padStart(2, "0");
  const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
  const year = localDate.getFullYear().toString().substr(-4);
  const formattedDate = `${year}-${month}-${day}`;
  const [attachments, setAttachments] = useState<fileType[]>([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [departmentInfo, setDepartmentInfo] = useState(false);
  const [patientPhone, setPatientPhone] = useState<any>("");
  const [searchCountry, setSearchCountry] = useState("");

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    trigger,
    watch,
  } = useForm<IManageMedicalForm>({});

  const handleReset = () => {
    setValue(MOBILE_NO, "");
    setValue(ATTACHMENTS, []);
    dispatch(clearState());
    setSelectedPackage("");
    setAttachments([]);
    setPatientPhone("");
  };

  // edit api call
  useEffect(() => {
    let data = {
      id: location?.state?.id,
    };
    location?.state?.id &&
      dispatch(getAllMedicalCenterById(requestGenerator(data)));
  }, []);

  const onSubmit: SubmitHandler<IManageMedicalForm> = (data: any) => {
    const countryObj = countryList?.find((item: any) => {
      return item?.country_code === data?.country?.value;
    });

    data.isBranchLimit = Boolean(watch(IS_BRANCH_LIMIT));
    data.isDepartmentLimit = Boolean(watch(IS_DEPARTMENT_LIMIT));
    data.isPatientLimit = Boolean(watch(IS_PATIENT_LIMIT));
    data.isAttachmentsLimit = Boolean(watch(IS_ATTACHMENT_LIMIT));
    data.max_branch_limit = Boolean(watch(IS_BRANCH_LIMIT))
      ? Number(watch(BRANCH_LIMIT))
      : 0;
    data.max_department_limit = Boolean(watch(IS_DEPARTMENT_LIMIT))
      ? Number(watch(DEPARTMENT_LIMIT))
      : 0;
    data.max_patient_limit = Boolean(watch(IS_PATIENT_LIMIT))
      ? Number(watch(PATIENT_LIMIT))
      : 0;
    data.max_attachment_limit = Boolean(watch(IS_ATTACHMENT_LIMIT))
      ? Number(watch(ATTACHMENT_LIMIT))
      : 0;

    // country = {
    //   name: countryObj?.name,
    //   isd_code: countryObj?.isd_code,
    //   country_code: countryObj?.country_code,
    //   timezones: {
    //     name: countryObj?.timezones?.name,
    //     offset: countryObj?.timezones?.offset,
    //     offset_name: countryObj?.timezones?.offset_name,
    //   },
    // };

    // ADDRESS_LINE_2
    // NOTES
    // WEBSITE
    // ATTACHMENTS

    // let formData: any = {
    //   mc_name: data.mc_name,
    //   poc_name: data.poc_name,
    //   poc_email: data.poc_email,
    //   expiry_date: data.expiry_date,
    //   package_id: data.package_id,
    //   max_users_limit: data.max_users_limit,
    //   db_uri: data.db_uri,
    //   poc_phone: data.poc_phone,
    //   addressid: data.addressid,
    // address_line_1: data.address_line_1,
    // country: data.country,
    // state: data.state,
    // city: data.city,
    // zipcode: data.zipcode,
    // }
    // if (data.address_line_2?.trim()) {
    //   formData.address_line_2 = data.address_line_2
    // }
    // if (
    //   !data.attachments ||
    //   data.attachments?.length !== 0 ||
    //   data.attachments !== undefined
    // ) {
    //   formData.attachments = data.attachments
    // }
    // if (data.notes?.trim()) {
    //   formData.notes = data.notes
    // }
    // if (data.website_url?.trim()) {
    //   formData.website_url = data.website_url
    // }
    // if (data.address_line_1?.trim()) {
    //   formData.address_line_1 = data.address_line_1
    // }
    // if (data.country?.trim()) {
    //   formData.country = data.country
    // }
    // if (data.state?.trim()) {
    //   formData.state = data.state
    // }
    // if (data.city?.trim()) {
    //   formData.city = data.city
    // }
    // if (data.zipcode?.trim()) {
    //   formData.zipcode = data.zipcode
    // }
    if (location?.state?.id) {
      let data1 = {
        id: location?.state?.id,
        // data: { ...formData },
        data: {
          ...data,
          country: {
            name: countryObj?.name,
            isd_code: countryObj?.isd_code,
            country_code: countryObj?.country_code,
            code: countryObj?.code,
            currency: countryObj?.currency,
            timezones: {
              name: countryObj?.timezones?.name,
              offset: countryObj?.timezones?.offset,
              offset_name: countryObj?.timezones?.offset_name,
            },
          },
        },
      };
      dispatch(updateMedicalCenterById(requestGenerator(data1))).then((e) => {
        if (e.type === `${UPDATE_MEDICAL_CENTER_BY_ID}/fulfilled`) {
          navigate("/medicalcenter");
        }
      });
    } else {
      dispatch(
        createMedicalCenter(
          requestGenerator({
            ...data,
            country: {
              name: countryObj?.name,
              isd_code: countryObj?.isd_code,
              country_code: countryObj?.country_code,
              code: countryObj?.code,
              currency: countryObj?.currency,
              timezones: {
                name: countryObj?.timezones?.name,
                offset: countryObj?.timezones?.offset,
                offset_name: countryObj?.timezones?.offset_name,
              },
            },
          })
        )
      ).then((e) => {
        if (e.type === `${ADD_MEDICAL_CENTER_TYPE}/fulfilled`) {
          navigate("/medicalcenter");
        }
      });
    }
  };

  useEffect(() => {
    if (medicalCenterByIdData?._id) {
      reset(medicalCenterByIdData);
      setValue(ATTACHMENTS, medicalCenterByIdData[ATTACHMENTS]);
      setPatientPhone(medicalCenterByIdData?.poc_phone);
      setValue(MOBILE_NO, medicalCenterByIdData?.poc_phone);
      setValue(COUNTRY, {
        label: medicalCenterByIdData?.country?.name,
        value: medicalCenterByIdData?.country?.country_code,
      });
    }
  }, [reset, medicalCenterByIdData, setValue]);

  useEffect(() => {
    if (
      medicalCenterByIdData[ATTACHMENTS] &&
      medicalCenterByIdData[ATTACHMENTS].length > 0
    ) {
      const attachementsData = medicalCenterByIdData[ATTACHMENTS]?.map(
        (item: fileType, index: number) => {
          let nameWithId =
            item.name.split("/")[item.name.split("/").length - 1] ?? "";
          let nameWithoutID = nameWithId
            ? nameWithId.split("-")[nameWithId.split("-").length - 1]
            : "";
          const finalItem = {
            name: nameWithoutID ?? "File.pdf",
            data_uri: item?.data_uri,
          };
          return finalItem;
        }
      );
      setValue(ATTACHMENTS, attachementsData);
      setAttachments(attachementsData);
    } else {
      setValue(ATTACHMENTS, []);
      setAttachments([]);
    }
  }, [medicalCenterByIdData[ATTACHMENTS]]);

  useEffect(() => {
    if (medicalCenterByIdData[EXPIRY_DATE]) {
      const formatData = utcToDate(medicalCenterByIdData[EXPIRY_DATE], true);
      setValue(EXPIRY_DATE, formatData);
    }
  }, [medicalCenterByIdData[EXPIRY_DATE], utcToDate]);

  useEffect(() => {
    if (medicalCenterByIdData[PACKAGE_ID]) {
      // setValue(PACKAGE_ID, medicalCenterByIdData?.package_id);
      setSelectedPackage(medicalCenterByIdData?.package_id);
    }
  }, [medicalCenterByIdData[PACKAGE_ID]]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearState());
  //   };
  // }, []);

  // const handleChange = (e: any) => {
  //   const value = e.target.value;
  //   if (value.length === 1 && value === " ") {
  //     e.target.value = "";
  //   } else if (
  //     value.length > 1 &&
  //     value[0] === " " &&
  //     value[value.length - 1] === " "
  //   ) {
  //     e.target.value = value.trim();
  //   }
  // };

  // const [date, setDate] = useState("");

  // const handleDateChange = (e: any) => {
  //   setDate(e.target.value);
  // };

  // const today = new Date().toISOString().split("T")[0];

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 40) {
      event.preventDefault();
    }
  };

  const validatePhoneWithCode = (number: string, code: any) => {
    return String(number).length > String(code).length && number != code;
  };
  const deb = debounce((text) => {
    if (text?.length) {
      setSearchCountry(text);
    }
  }, 1000);
  useEffect(() => {
    const data = {
      page: 1,
      pageSize: medicalCenterByIdData?._id ? 300 : 50,
      search: searchCountry,
    };
    dispatch(getAllCountryList(requestGenerator(data)));
  }, [searchCountry, medicalCenterByIdData]);

  const countryValue = watch(COUNTRY);
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.pageWrapper}>
        <form
          className={styles.addMedicalCenterForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.formFieldRow}>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={NAME} className={styles.formLabel}>
                  Medical Center Name<span className="asterick">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Medical Center Name"
                  className={styles.inputField}
                  {...register(NAME, manageMedicalCenterValidators[NAME])}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              {errors[NAME] && (
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  <p className="dashboardFormError">{errors[NAME].message}</p>
                </div>
              )}
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label
                  htmlFor={PRIMARY_CONTACT_NAME}
                  className={styles.formLabel}
                >
                  Primary Contact Name
                  <span className="asterick">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Primary Contact Name"
                  className={styles.inputField}
                  {...register(
                    PRIMARY_CONTACT_NAME,
                    manageMedicalCenterValidators[PRIMARY_CONTACT_NAME]
                  )}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[PRIMARY_CONTACT_NAME] && (
                  <p className="dashboardFormError">
                    {errors[PRIMARY_CONTACT_NAME].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={EMAIL} className={styles.formLabel}>
                  MC Admin Email<span className="asterick">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter MC Admin Email"
                  className={styles.inputField}
                  {...register(
                    POC_EMAIL,
                    manageMedicalCenterValidators[POC_EMAIL]
                  )}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[POC_EMAIL] && (
                  <p className="dashboardFormError">
                    {errors[POC_EMAIL].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={MOBILE_NO} className={styles.formLabel}>
                  Mobile No.<span className="asterick">*</span>
                </label>
                <PhoneInput
                  country={"kw"}
                  placeholder="Enter Mobile No."
                  {...register(
                    MOBILE_NO,
                    manageMedicalCenterValidators[MOBILE_NO]
                  )}
                  // value={getValues(MOBILE_NO)}
                  value={patientPhone ?? ""}
                  // onChange={(phone) => {
                  //   const formattedPhone = phone && `+${phone}`
                  //   setValue(MOBILE_NO, formattedPhone.trim())
                  //   trigger(MOBILE_NO)
                  // }}
                  onChange={(phone: any, country: any, e: any) => {
                    const formattedPhone = phone && `+${phone}`;
                    const valid = validatePhoneWithCode(
                      phone,
                      country?.dialCode
                    );
                    if (valid) {
                      setValue(MOBILE_NO, formattedPhone);
                      setPatientPhone(formattedPhone);
                      trigger(MOBILE_NO);
                      clearErrors(MOBILE_NO);
                    } else {
                      setError(MOBILE_NO, {
                        type: "custom",
                        message: "Please Enter Valid Mobile No.",
                      });
                    }
                  }}
                  inputClass={styles.phoneNumberInput}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[MOBILE_NO] && (
                  <p className="dashboardFormError">
                    {errors[MOBILE_NO].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={EXPIRY_DATE} className={styles.formLabel}>
                  Expiry Date<span className="asterick">*</span>
                </label>
                <input
                  type="date"
                  placeholder="Enter Expiry Date"
                  min={new Date().toISOString().split("T")[0]}
                  max="9999-12-31"
                  defaultValue={
                    medicalCenterByIdData[EXPIRY_DATE] !== undefined
                      ? utcToDate(medicalCenterByIdData[EXPIRY_DATE], true)
                      : ""
                  }
                  className={styles.inputField}
                  {...register(
                    EXPIRY_DATE,
                    manageMedicalCenterValidators[EXPIRY_DATE]
                  )}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[EXPIRY_DATE] && (
                  <p className="dashboardFormError">
                    {errors[EXPIRY_DATE].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={PACKAGE_ID} className={styles.formLabel}>
                  Package<span className="asterick">*</span>
                </label>
                <select
                  className={styles.inputField}
                  {...register(
                    PACKAGE_ID,
                    manageMedicalCenterValidators[PACKAGE_ID]
                  )}
                  value={selectedPackage}
                  onChange={(e) => {
                    setSelectedPackage(e.target.value);
                  }}
                >
                  <option value="">Select Package</option>
                  {packageData &&
                    packageData
                      ?.filter((item: any) => item?.is_active)
                      .map((item: any, i: number) => {
                        return (
                          <option
                            key={i}
                            value={item?._id}
                            selected={
                              item?._id === medicalCenterByIdData[PACKAGE_ID]
                            }
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                </select>
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[PACKAGE_ID] && (
                  <p className="dashboardFormError">
                    {errors[PACKAGE_ID].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={NO_OF_USER} className={styles.formLabel}>
                  No. of Users<span className="asterick">*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter No. of Users"
                  className={styles.inputField}
                  {...register(
                    NO_OF_USER,
                    manageMedicalCenterValidators[NO_OF_USER]
                  )}
                  onChange={(e) => trimValue(e)}
                  // onKeyDown={handleKeyDown}
                  onKeyDown={(e: any) => disableArrowKey(e)}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[NO_OF_USER] && (
                  <p className="dashboardFormError">
                    {errors[NO_OF_USER].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={ADDRESS_LINE_1} className={styles.formLabel}>
                  Address Line 1
                </label>
                <input
                  type="text"
                  placeholder="Enter Address Line 1"
                  className={styles.inputField}
                  {...register(ADDRESS_LINE_1)}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              {/* <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[ADDRESS_LINE_1] && (
                  <p className="dashboardFormError">
                    {errors[ADDRESS_LINE_1].message}
                  </p>
                )}
              </div> */}
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={ADDRESS_LINE_2} className={styles.formLabel}>
                  Address Line 2
                </label>
                <input
                  type="text"
                  placeholder="Enter Address Line 2"
                  className={styles.inputField}
                  {...register(ADDRESS_LINE_2)}
                  onChange={(e) => trimValue(e)}
                />
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={ZIP_CODE} className={styles.formLabel}>
                  Zipcode
                </label>
                <input
                  type="number"
                  placeholder="Enter Zipcode"
                  className={styles.inputField}
                  {...register(ZIP_CODE)}
                  onChange={(e) => trimValue(e)}
                  onWheel={(e: any) => {
                    e.target.blur();
                  }}
                  onScroll={disableScroll()}
                  onKeyDown={(e: any) => disableArrowKey(e)}
                />
              </div>
              {/* <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[ZIP_CODE] && (
                  <p className="dashboardFormError">
                    {errors[ZIP_CODE].message}
                  </p>
                )}
              </div> */}
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={CITY} className={styles.formLabel}>
                  City
                </label>
                <input
                  type="text"
                  placeholder="Enter City"
                  className={styles.inputField}
                  {...register(CITY)}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              {/* <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[CITY] && (
                  <p className="dashboardFormError">{errors[CITY].message}</p>
                )}
              </div> */}
            </div>

            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={STATE} className={styles.formLabel}>
                  State
                </label>
                <input
                  type="text"
                  placeholder="Enter State"
                  className={styles.inputField}
                  {...register(STATE)}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              {/* <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[STATE] && (
                  <p className="dashboardFormError">{errors[STATE].message}</p>
                )}
              </div> */}
            </div>

            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={COUNTRY} className={styles.formLabel}>
                  Country
                </label>
                {/* <input
                  type="text"
                  placeholder="Enter Country"
                  className={styles.inputField}
                  {...register(COUNTRY)}
                  onChange={(e) => trimValue(e)}
                /> */}
                <Select
                  className={styles.selectField}
                  // placeholder={
                  //   t("IPDBooking.SelectPatient") || "Select Patient"
                  // }
                  placeholder={"Select Country"}
                  closeMenuOnSelect={true}
                  // components={{ DropdownIndicator }}
                  {...register(COUNTRY, manageMedicalCenterValidators[COUNTRY])}
                  value={watch(COUNTRY)}
                  options={countryList?.map((item: any) => {
                    return {
                      value: item?.country_code,
                      label: item?.name,
                    };
                  })}
                  isSearchable={true}
                  onChange={(e: any) => {
                    setValue(COUNTRY, e);
                  }}
                  onInputChange={(newValue) => {
                    deb(newValue);
                  }}
                  // maxMenuHeight={200}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[COUNTRY] && (
                  <p className="dashboardFormError">
                    {errors[COUNTRY].message as any}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={WEBSITE} className={styles.formLabel}>
                  Website
                </label>
                <input
                  type="text"
                  placeholder="Enter Website"
                  className={styles.inputField}
                  {...register(WEBSITE)}
                  onChange={(e) => trimValue(e)}
                />
              </div>
            </div>
            {/* <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={DB_URI} className={styles.formLabel}>
                  DB URL<span className="asterick">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter DB URL"
                  className={styles.inputField}
                  {...register(DB_URI, manageMedicalCenterValidators[DB_URI])}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[DB_URI] && (
                  <p className="dashboardFormError">{errors[DB_URI].message}</p>
                )}
              </div>
            </div> */}

            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label
                  htmlFor={ATTACHMENTS}
                  style={{ alignSelf: "flex-start", paddingTop: "10px" }}
                  className={styles.formLabel}
                >
                  Attachments
                </label>
                <AttachFiles
                  fileKey={ATTACHMENTS}
                  defaultAttachments={attachments ?? []}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  isMultiSelect={true}
                  setErrors={setError}
                  file={file}
                  customClass={styles.attacheFileName}
                  setFile={setFile}
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors[ATTACHMENTS] && (
                  <p className="dashboardFormError">
                    {errors[ATTACHMENTS].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainerLimit}>
                <label htmlFor={BRANCH_LIMIT} className={styles.formLabel}>
                  Branch Limit
                </label>
                <div>
                  <input
                    placeholder="Enter Branch Limit"
                    className={
                      !watch(IS_BRANCH_LIMIT)
                        ? styles.disableField
                        : styles.inputFieldLimit
                    }
                    {...register(
                      BRANCH_LIMIT,
                      manageMedicalCenterValidators[BRANCH_LIMIT]
                    )}
                    onChange={(e) => trimValue(e)}
                    disabled={!watch(IS_BRANCH_LIMIT)}
                    type="number"
                    onKeyDown={(e: any) => {
                      disableArrowKey(e);
                    }}
                    onWheel={(e: any) => {
                      e.target.blur();
                    }}
                  />
                  <div className={styles.errorContainer}>
                    {errors[BRANCH_LIMIT] && (
                      <p className="dashboardFormError">
                        {errors[BRANCH_LIMIT].message}
                      </p>
                    )}
                  </div>
                </div>
                {!watch(IS_BRANCH_LIMIT) ? (
                  <UncheckIcon
                    fillColor={colors.grey2}
                    handleClick={() => setValue(IS_BRANCH_LIMIT, true)}
                    customClass={styles.checkIconStyle}
                  />
                ) : (
                  <CheckIcon
                    fillColor={colors.green1}
                    handleClick={() => {
                      setValue(IS_BRANCH_LIMIT, false);
                      setValue(BRANCH_LIMIT, "");
                    }}
                    customClass={styles.checkIconStyle}
                  />
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainerLimit}>
                <label htmlFor={DEPARTMENT_LIMIT} className={styles.formLabel}>
                  Department Limit{" "}
                  <span
                    onMouseEnter={() => setDepartmentInfo(true)}
                    onMouseLeave={() => setDepartmentInfo(false)}
                    className={styles.infoContainer}
                  >
                    <InfoIcon fillColor={colors.grey2} />
                    {departmentInfo && (
                      <p className={styles.textInfo}>
                        In the medical center, certain departments come
                        pre-configured so, minimum limits is set based on the
                        selected package.
                      </p>
                    )}
                  </span>
                </label>

                <div>
                  <input
                    placeholder="Enter Department Limit"
                    className={
                      !watch(IS_DEPARTMENT_LIMIT)
                        ? styles.disableField
                        : styles.inputFieldLimit
                    }
                    {...register(
                      DEPARTMENT_LIMIT,
                      manageMedicalCenterValidators[DEPARTMENT_LIMIT]
                    )}
                    onChange={(e) => trimValue(e)}
                    disabled={!watch(IS_DEPARTMENT_LIMIT)}
                    type="number"
                    onKeyDown={(e: any) => {
                      disableArrowKey(e);
                    }}
                    onWheel={(e: any) => {
                      e.target.blur();
                    }}
                  />
                  <div className={styles.errorContainer}>
                    {errors[DEPARTMENT_LIMIT] && (
                      <p className="dashboardFormError">
                        {errors[DEPARTMENT_LIMIT].message}
                      </p>
                    )}
                  </div>
                </div>
                {!watch(IS_DEPARTMENT_LIMIT) ? (
                  <UncheckIcon
                    fillColor={colors.grey2}
                    handleClick={() => setValue(IS_DEPARTMENT_LIMIT, true)}
                    customClass={styles.checkIconStyle}
                  />
                ) : (
                  <CheckIcon
                    fillColor={colors.green1}
                    handleClick={() => {
                      setValue(IS_DEPARTMENT_LIMIT, false);
                      setValue(DEPARTMENT_LIMIT, "");
                    }}
                    customClass={styles.checkIconStyle}
                  />
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainerLimit}>
                <label htmlFor={PATIENT_LIMIT} className={styles.formLabel}>
                  Patient Limit
                </label>
                <div>
                  <input
                    placeholder="Enter Patient Limit"
                    className={
                      !watch(IS_PATIENT_LIMIT)
                        ? styles.disableField
                        : styles.inputFieldLimit
                    }
                    {...register(
                      PATIENT_LIMIT,
                      manageMedicalCenterValidators[PATIENT_LIMIT]
                    )}
                    onChange={(e) => trimValue(e)}
                    disabled={!watch(IS_PATIENT_LIMIT)}
                    type="number"
                    onKeyDown={(e: any) => {
                      disableArrowKey(e);
                    }}
                    onWheel={(e: any) => {
                      e.target.blur();
                    }}
                  />
                  <div className={styles.errorContainer}>
                    {errors[PATIENT_LIMIT] && (
                      <p className="dashboardFormError">
                        {errors[PATIENT_LIMIT].message}
                      </p>
                    )}
                  </div>
                </div>
                {!watch(IS_PATIENT_LIMIT) ? (
                  <UncheckIcon
                    fillColor={colors.grey2}
                    handleClick={() => setValue(IS_PATIENT_LIMIT, true)}
                    customClass={styles.checkIconStyle}
                  />
                ) : (
                  <CheckIcon
                    fillColor={colors.green1}
                    handleClick={() => {
                      setValue(IS_PATIENT_LIMIT, false);
                      setValue(PATIENT_LIMIT, "");
                    }}
                    customClass={styles.checkIconStyle}
                  />
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainerLimit}>
                <label htmlFor={ATTACHMENT_LIMIT} className={styles.formLabel}>
                  Attachments Limit (GB)
                </label>
                <div>
                  <input
                    placeholder="Enter Attachment Limit"
                    className={
                      !watch(IS_ATTACHMENT_LIMIT)
                        ? styles.disableField
                        : styles.inputFieldLimit
                    }
                    {...register(
                      ATTACHMENT_LIMIT,
                      manageMedicalCenterValidators[ATTACHMENT_LIMIT]
                    )}
                    onChange={(e) => trimValue(e)}
                    disabled={!watch(IS_ATTACHMENT_LIMIT)}
                    type="number"
                    onKeyDown={(e: any) => {
                      disableArrowKey(e);
                    }}
                    onWheel={(e: any) => {
                      e.target.blur();
                    }}
                  />
                  <div className={styles.errorContainer}>
                    {errors[ATTACHMENT_LIMIT] && (
                      <p className="dashboardFormError">
                        {errors[ATTACHMENT_LIMIT].message}
                      </p>
                    )}
                  </div>
                </div>
                {!watch(IS_ATTACHMENT_LIMIT) ? (
                  <UncheckIcon
                    fillColor={colors.grey2}
                    handleClick={() => setValue(IS_ATTACHMENT_LIMIT, true)}
                    customClass={styles.checkIconStyle}
                  />
                ) : (
                  <CheckIcon
                    fillColor={colors.green1}
                    handleClick={() => {
                      setValue(IS_ATTACHMENT_LIMIT, false);
                      setValue(ATTACHMENT_LIMIT, "");
                    }}
                    customClass={styles.checkIconStyle}
                  />
                )}
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={NOTES} className={styles.formLabel}>
                  Notes
                </label>
                <textarea
                  placeholder="Enter Notes"
                  className={styles.textArea}
                  {...register(NOTES)}
                  onChange={(e) => trimValue(e)}
                />
              </div>
            </div>
          </div>
          <div className={styles.formButtonContainer}>
            <Button title="Submit" type="submit" />
            <Button
              title="Reset"
              type="reset"
              customClass={styles.resetBtn}
              handleClick={() => handleReset()}
            />
            <Button
              title="Back"
              type="button"
              customClass={styles.resetBtn}
              handleClick={() => navigate("/medicalcenter")}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ManageMedicalCenter;
