import React, { useEffect, useState } from "react";
import styles from "./import-utility.module.scss";
import Select from "react-select";
import SampleCSV from "../../components/common/sample-csv/SampleCSV";
import AttachFiles from "../../components/common/attach-files/single-file/AttachSingleFile";
import { ATTACHMENTS } from "../../constants/constant";
import { fileType, IManageUtilityForm } from "../../interfaces/interfaces";
import { useForm } from "react-hook-form";
import {
  importComplianceUtility,
  importDoctorsUtility,
  importInsuranceUtility,
  importMedicinesUtility,
} from "../../redux/features/import-utility/importUtilityAsynActions";
import { requestGenerator } from "../../utils/payloadGenerator";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Button from "../../components/common/button/Button";
import { customDropdownStyles, dataURI, downloadCSV } from "../../utils/utils";
import { csvINVOptions } from "../../constants/data";
import Loader from "../../components/common/spinner/Loader";

const ImportUtility = () => {
  const [attachments, setAttachments] = useState<fileType[]>([]);
  const [file, setFile] = useState<any>({ name: "", data_uri: "" });

  const complainsData = [
    {
      label: "Malaffi",
      value: "MALAFFI",
    },
  ];

  const utilityTypeData = [
    {
      label: "icd10",
      value: "ICD10",
    },
    {
      label: "Insurance",
      value: "INSURANCE",
    },
    {
      label: "Doctors",
      value: "DOCTORS",
    },
    {
      label: "Medicines",
      value: "MEDICINES",
    },
  ];
  const { isLoading } = useAppSelector((state) => state.importUtility);
  const dispatch = useAppDispatch();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    trigger,
    watch,
  } = useForm<IManageUtilityForm>({});

  const utilityType: any = watch("utilityType");
  const compliance: any = watch("compliance");

  const attachment: any = watch(ATTACHMENTS) ?? [];
  const fileName = attachment?.[0];

  const onSubmit = (data: any) => {
    if (data?.utilityType?.value === "ICD10") {
      const requestData = {
        complains_file: file,
        compliance: data?.compliance?.value,
      };
      dispatch(importComplianceUtility(requestGenerator(requestData))).then(
        (res) => {
          if (res.type === "importUtility/compliance/fulfilled") {
            reset();
            setValue("utilityType",null)
          }
        }
      );
    } else if (data?.utilityType?.value === "INSURANCE") {
      const requestData = {
        insurance_file: file,
        compliance: data?.compliance?.value,
      };
      dispatch(importInsuranceUtility(requestGenerator(requestData))).then(
        (res) => {
          if (res.type === "importUtility/insurance/fulfilled") {
            reset();
            setValue("utilityType",null)
          }
        }
      );
    } else if (data?.utilityType?.value === "MEDICINES") {
      const requestData = {
        medicines_file: file,
        compliance: data?.compliance?.value,
      };
      dispatch(importMedicinesUtility(requestGenerator(requestData))).then(
        (res) => {
          if (res.type === "importUtility/medicines/fulfilled") {
            reset();
            setValue("utilityType",null)
          }
        }
      );
    } 
    else {
      const requestData = {
        doctors_file: file,
        compliance: data?.compliance?.value,
      };
      dispatch(importDoctorsUtility(requestGenerator(requestData))).then(
        (res) => {
          if (res.type === "importUtility/doctors/fulfilled") {
            reset();
            setValue("utilityType",null)
          }
        }
      );
    }
  };
  useEffect(() => {
    setValue("compliance", complainsData[0]);
  }, []);

  useEffect(() => {
    const fileList: fileType = { name: "", data_uri: "" };
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName);
        fileList.data_uri = result;
        fileList.name = fileName.name;

        setFile(fileList);
      } catch (error) {
        console.log({ error });
      }
    };
    if (fileName) {
      getDataURI(fileName);
    }
  }, [fileName]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loader />}
      <div className={styles.mainContainer}>
        <div className={styles.inputFieldContainer}>
          <label className={styles.formLabel}>
            Utility Type
            <span className="asterick">*</span>
          </label>
          <div className={styles.inlineItems}>
            <Select
              className={styles.selectInputField}
              placeholder="Select Type"
              {...register("utilityType", {
                required: "Please Select UtilityType",
              })}
              value={utilityType}
              closeMenuOnSelect={true}
              options={utilityTypeData}
              isSearchable={true}
              maxMenuHeight={170}
              onChange={(e: any) => {
                setValue("utilityType", e);
              }}
              styles={customDropdownStyles}
            />
            {errors["utilityType"] && (
              <p className="errorText">
                {errors["utilityType"].message as any}
              </p>
            )}
          </div>
        </div>

        <div className={styles.inputFieldContainer}>
          <label className={styles.formLabel}>Compliance</label>
          <div className={styles.inlineItems}>
            <Select
              className={styles.selectInputField}
              placeholder="Select Compliance"
              {...register("compliance", {
                required: "Please Select Compliance",
              })}
              closeMenuOnSelect={true}
              value={compliance}
              options={complainsData}
              isSearchable={true}
              maxMenuHeight={200}
              defaultValue={complainsData[0]}
              onChange={(e: any) => {
                setValue("compliance", e);
              }}
              styles={customDropdownStyles}
            />
            {errors["compliance"] && (
              <p className="errorText">{errors["compliance"].message as any}</p>
            )}
          </div>
        </div>

        <div className={styles.inputFieldContainer}>
          <label className={styles.formLabel}>Sample CSV</label>
          <div className={styles.inlineItems}>
            <SampleCSV
              onExport={() => {
                downloadCSV(
                  csvINVOptions[utilityType?.value],
                  `${utilityType.label
                    .toLocaleLowerCase()
                    .replace(/ /g, "_")}_sample.csv`
                );
              }}
              disable={!utilityType}
              style={{ marginLeft: "2px" }}

            />
          </div>
        </div>

        <div className={styles.inputFieldContainer}>
          <label className={styles.formLabel}>Upload</label>
          <div className={styles.inlineItems}>
            <AttachFiles
              register={register}
              fileKey={ATTACHMENTS}
              fileList={attachment}
              id={utilityType?.label}
              isName={true}
              isDocument={true}
              validation={{ required: true }}
            />
            {errors[ATTACHMENTS] && (
              <p className="errorText">Please upload File</p>
            )}
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button title="Submit" type="submit" />
        </div>
      </div>
    </form>
  );
};

export default ImportUtility;
