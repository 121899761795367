import { FC, useEffect, useRef, useState } from 'react'
import styles from './medicalCenter.module.scss'
import { useNavigate } from 'react-router-dom'
import Table from '../../../components/common/table/Table'
import { medicalTableHeaderData } from '../../../constants/data'
import Pagination from '../../../components/common/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Loader from '../../../components/common/spinner/Loader'
import {
  activeMedicalCenterById,
  getAllMedicalCenter,
} from '../../../redux/features/medical-center/medicalCenterAsynActions'
import Popup from '../../../components/common/popup/Popup'
import NotesPopup from '../../../components/common/modal/notes-popup/NotesPopup'
import { getAllPackage } from '../../../redux/features/package/packageAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import DeletePopup from '../../../components/common/modal/delete-popup/DeletePopup'
import CreatedUsersPopup from '../../../components/common/created-users-popup/CreatedUsersPopup'
import { utcToDate } from '../../../utils/utils'
import Button from '../../../components/common/button/Button'
import SearchDropDown from '../../../components/common/search-dropDown/SearchDropDown'
import { clearState } from '../../../redux/features/medical-center/medicalCenterSlice'

interface IMedicalCenter {}

const MedicalCenter: FC<IMedicalCenter> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const location = useLocation();
  const { packageData } = useAppSelector((state) => state.package)
  const { mcLoading, isLoading, medicalCenterData, isStatusUpdated } =
    useAppSelector((state) => state.medicalCenter)

  const [showNotes, setShowNotes] = useState<boolean>(false)
  const [notesPopData, setNotesPopData] = useState({})

  const [deletePopData, setDeletePopData] = useState({})
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [showCreateUsers, setShowCreateUsers] = useState<boolean>(false)
  const [createUsersPopData, setCreateUsersPopData] = useState({})
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [searchPopData, setSearchPopData] = useState<any>({})
  const [packageFilter, setPackageFilter] = useState<any>({})
  console.log('packageFilter??>>>', packageFilter?._id)
  const [searchValue, setSearchValue] = useState('')
  const [totalData, setTotalData] = useState(0)
  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  const requestData = {
    search: searchValue,
    pkg: packageFilter?._id ?? '',
    page: pageIndex,
    pageSize: dataPerPage,
  }
  // useEffect(() => {
  //   dispatch(getAllMedicalCenter(requestGenerator(requestData))).then(
  //     (result) => {
  //       setTotalPage(result.payload.lastPage);
  //       setTotalData(result.payload.total);
  //     }
  //   );
  // }, [dispatch, dataPerPage, isStatusUpdated, packageFilter?._id, pageIndex]);

  // Api call for Get All Package
  useEffect(() => {
    dispatch(getAllPackage(requestGenerator({})))
  }, [dispatch])

  // notes modal close
  const handleModalClose = () => {
    setShowNotes(false)
  }
  // handle MC notes
  const handleMCNotes = (item: any) => {
    setShowNotes(!showNotes)
    setNotesPopData(item)
  }

  // delete modal close
  const handleDeleteModalClose = () => {
    setShowDelete(false)
  }
  // handle MC delete
  const handleDeleteMC = (item: any) => {
    setShowDelete(!showDelete)
    setDeletePopData(item)
  }

  // MC record getbyId
  const handleGetMcById = (item: any) => {
    item &&
      navigate(`managemedicalcenter`, {
        state: {
          id: item,
        },
      })
    dispatch(clearState())
  }

  // create user modal close
  const handleCreateUsersModalClose = () => {
    setShowCreateUsers(false)
  }

  // create user
  const handleCreateUsers = (item: any) => {
    setShowCreateUsers(!showCreateUsers)
    setCreateUsersPopData(item)
  }

  // handletoggle
  const [toggle, setToggle] = useState<boolean>(true)
  const [toogleValue, setToggleValue] = useState()

  const handleActiveMC = (item: any) => {
    if (item?.config !== null) {
      setToggle(!toggle)
      setToggleValue(item?._id)
      let activePayload = {
        id: item?._id,
        data: {
          is_active: !item?.is_active,
        },
      }

      dispatch(activeMedicalCenterById(requestGenerator(activePayload))).then(
        () => {
          const requestData = {
            search: searchValue,
            pkg: packageFilter?.value,
            page: pageIndex,
            pageSize: dataPerPage,
          }
          dispatch(getAllMedicalCenter(requestGenerator(requestData))).then(
            (result) => {
              setTotalPage(result.payload.lastPage)
              setTotalData(result.payload.total)
            }
          )
        }
      )
    } else {
      return
    }
  }

  const handleInputSearch = () => {
    if (packageFilter?._id === undefined) {
      setPackageFilter({})
    }
    setPageIndex(1)
    dispatch(getAllMedicalCenter(requestGenerator(requestData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }

  /* function defination for search */
  const handleSearch = () => {
    setPageIndex(1)
    if (searchValue?.length > 0) {
      const requestData = {
        search: searchValue,
        pkg: packageFilter?.value,
        page: 1,
        pageSize: dataPerPage,
      }
      dispatch(getAllMedicalCenter(requestGenerator(requestData))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }
  /* function defination for search */

  /*useEffect for get API*/
  useEffect(() => {
    const requestData = {
      search: searchValue,
      pkg: packageFilter?.value,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllMedicalCenter(requestGenerator(requestData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [dataPerPage, pageIndex, packageFilter])
  /*useEffect for get API*/

  return (
    <>
      {(isLoading || mcLoading) && <Loader />}
      {showNotes && (
        <Popup
          Children={NotesPopup}
          popData={notesPopData}
          handleClose={() => handleModalClose()}
        />
      )}
      {showDelete && (
        <Popup
          Children={DeletePopup}
          popData={deletePopData}
          handleClose={() => handleDeleteModalClose()}
        />
      )}
      {showCreateUsers && (
        <Popup
          Children={CreatedUsersPopup}
          popData={createUsersPopData}
          handleClose={() => handleCreateUsersModalClose()}
        />
      )}

      <div className={styles.mainContainer}>
        <div className={styles.addMedicalCenter}>
          <p className={styles.medicalCenterTitle}>Medical Center List</p>
          <Button
            title="Add Medical Center"
            customClass={styles.medicalCenterButtonStyle}
            handleClick={() => {
              dispatch(clearState())
              navigate('managemedicalcenter')
            }}
          />
        </div>
        <div className={styles.addMedicalCenter}>
          <p className={styles.medicalCenterTitle}></p>
          <Button
            title="Medical center utility"
            customClass={styles.medicalCenterButtonStyle}
            handleClick={() => {
              navigate('medicalcenter-utility')
            }}
          />
        </div>
        <SearchDropDown
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          dropDownData={packageData}
          dropdownInitialState="Select package"
          dropwnInitialValueOption="All packages"
          placeHolder="Search by Name"
          setPackageFilter={setPackageFilter}
          packageFilter={packageFilter}
          searchPopData={searchPopData}
          setSearchPopData={setSearchPopData}
          setCurrentPage={setPageIndex}
          handleInputSearch={handleInputSearch}
          handleOnChangeInput={(e: any) => {
            if (searchValue !== '' && e.target.value === '') {
              const requestData = {
                search: e.target.value,
                pkg: '',
                page: pageIndex,
                pageSize: dataPerPage,
              }
              dispatch(getAllMedicalCenter(requestGenerator(requestData))).then(
                (result) => {
                  setPackageFilter({})
                  setTotalPage(result.payload.lastPage)
                  setTotalData(result.payload.total)
                }
              )
            }
          }}
        />

        <div className={styles.tableContainer}>
          <Table
            tableHeaderData={medicalTableHeaderData}
            tableRowData={medicalCenterData.map((item: any) => {
              return {
                ...item,
                expiry_date: utcToDate(item.expiry_date),
              }
            })}
            handleAction={handleGetMcById}
            handleNotes={handleMCNotes}
            handleDeleteAction={handleDeleteMC}
            handleCreateUsers={handleCreateUsers}
            handleActiveMC={handleActiveMC}
            toogleValue={toogleValue}
          />
        </div>
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}

export default MedicalCenter
