// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_button__P2A2x {\n  background: var(--gredientColor);\n  border-radius: 6px;\n  width: 156px;\n  height: 42px;\n  border: none;\n  font-weight: var(--font-semibold);\n  font-size: var(--font-16);\n  line-height: 23px;\n  color: var(--white1);\n  cursor: pointer;\n  margin: 5px;\n}\n.button_button__P2A2x:disabled {\n  cursor: not-allowed !important;\n  opacity: 0.5;\n}", "",{"version":3,"sources":["webpack://./src/components/common/button/button.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,WAAA;AACF;AAAE;EACE,8BAAA;EACA,YAAA;AAEJ","sourcesContent":[".button {\n  background: var(--gredientColor);\n  border-radius: 6px;\n  width: 156px;\n  height: 42px;\n  border: none;\n  font-weight: var(--font-semibold);\n  font-size: var(--font-16);\n  line-height: 23px;\n  color: var(--white1);\n  cursor: pointer;\n  margin: 5px;\n  &:disabled {\n    cursor: not-allowed !important;\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_button__P2A2x"
};
export default ___CSS_LOADER_EXPORT___;
