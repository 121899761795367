import { FC, useRef } from 'react'
import styles from './header.module.scss'
import profileIcon from '../../../assets/images/headerProfileIcon.png'
import {
  TranslationIcon,
  NotificationIcon,
} from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { routes, MyRoutes } from '../../../components/app/routes/routeData'
// import { useAppDispatch } from "../../../hooks/index";
// import { userLogout } from "../../../redux/features/login/loginSlice";
import LogoutModal from '../../../components/common/modal/logout-modal/LogoutModal'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../../hooks'

const Header: FC = () => {
  const location = useLocation()
  const [pageTitle, setPageTitle] = useState<string>('HEALTHBOX')
  const [logoutModal, setLogoutModal] = useState<boolean>(false)
  const { userData } = useAppSelector((state) => state.login)
  // const dispatch = useAppDispatch();

  // Find the active route based on the current location

  const findActiveRoute = useCallback(
    (routes: MyRoutes[], pathname: string): void => {
      routes.forEach((item) => {
        if (pathname === item.location) {
          setPageTitle(item.header)
        }
        if (item.children.length > 0) {
          findActiveRoute(item.children, pathname)
        }
      })
    },
    []
  )

  useEffect(() => {
    findActiveRoute(routes, location.pathname)
  }, [findActiveRoute, location.pathname])

  const handleOpenModal = () => {
    setLogoutModal(!logoutModal)
  }

  const ref = useRef<any>()

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (logoutModal && ref.current && !ref.current.contains(e.target)) {
        setLogoutModal(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [logoutModal])
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.headerContainer}>
          <p className={styles.headerText}>{pageTitle}</p>
          <div className={styles.profileContainer}>
            {/* <p className={styles.title}>Forms</p> */}
            <TranslationIcon fillColor={colors.prussian_blue} />
            <NotificationIcon />
            <img
              src={profileIcon}
              alt="profile_img"
              onClick={handleOpenModal}
              className={styles.profileImg}
            />
          </div>
        </div>
        {logoutModal && <LogoutModal logoutRef={ref} />}
      </div>
    </>
  )
}

export default Header
