import { Link } from 'react-router-dom'

import {
  AppConfigIcon,
  Check,
  Error,
  MedicalCenterIcon,
  PackageIcon,
  Warning,
} from '../components/common/svg-components'
import { ISidebar, ITab } from '../interfaces/interfaces'
import * as React from 'react'
import { EditIcon } from '../components/common/svg-components/index'
import { PACKAGE_ID } from './constant'
import { colors } from './color'
export const sidebarData: ISidebar[] = [
  {
    id: 0,
    name: 'Manage Medical Centers',
    icon: MedicalCenterIcon,
    navigate: '/medicalcenter',
  },
  {
    id: 1,
    name: 'Manage Package',
    icon: PackageIcon,
    navigate: '/package',
  },
  {
    id: 2,
    name: 'Mobile App Configurations',
    icon: AppConfigIcon,
    navigate: '/mobileappconfiguration',
  },
]

export const packageHeaderData: any = [
  // {
  //   Header: "PACKAGE ID",
  //   accessor: "_id",
  // },
  {
    Header: 'PACKAGE NAME',
    accessor: 'name',
  },
  {
    Header: 'TOTAL MODULES',
    accessor: (row: any) => {
      return row?.module_ids?.length
    },
  },
  // {
  //   Header: "No. of Users", // max user limit
  //   accessor: "max_user_limit",
  // },
  {
    Header: 'DETAILS',
    accessor: 'description',
    Cell: () => {
      return React.createElement(
        Link,
        { to: '/', className: 'viewLinkStyle' },
        'View'
      )
    },
  },
  {
    Header: 'NOTES',
    accessor: 'notes',
    // Cell: () => {
    //   return React.createElement(
    //     Link,
    //     { to: "/", className: "viewLinkStyle" },
    //     "View"
    //   );
    // },
  },
  {
    Header: 'STATUS',
  },
  {
    Header: 'ACTIONS',
    Cell: () => {
      return React.createElement(
        Link,
        { to: 'managepackage' },
        React.createElement(EditIcon, {
          fillColor: '#CDD4D8',
        })
      )
    },
  },
]

export const medicalTableHeaderData: any = [
  // {
  //   Header: "MC ID",
  //   accessor: "mc_id",
  // },
  {
    Header: 'NAME',
    accessor: 'mc_name',
  },
  {
    Header: 'PACKAGE',
    accessor: (row: any) => {
      return row?.package_id?.name
    },
  },

  {
    Header: 'EXPIRY DATE',
    accessor: 'expiry_date',
  },
  {
    Header: 'PRIMARY CONTACT NAME',
    accessor: 'poc_name',
  },
  {
    Header: 'NOTES',
    accessor: 'notes',
    Cell: () => {
      return React.createElement(
        Link,
        { to: '/', className: 'viewLinkStyle' },
        'View'
      )
    },
  },
  {
    Header: 'EMAIL ID',
    accessor: 'poc_email',
  },
  {
    Header: 'MOBILE NO.',
    accessor: 'poc_phone',
  },
  {
    Header: 'ALLOCATED USERS',
    accessor: 'max_users_limit',
    // accessor: (row: any) => {
    //   return row?.package_id?.max_user_limit;
    // },
  },
  {
    Header: 'CREATED USERS',
    accessor: 'created_users_count',
  },
  {
    Header: 'STATUS',
  },
  {
    Header: 'ACTIONS',
    // Cell: () => {
    //   return <EditIcon fillColor="#FF0000" customClass="iconStyle" />;
    // },
  },
]

export const dropDownContentData = [
  'gold',
  'platinum',
  'silver',
  'premium',
  'custom',
]

export const countryCodeData = ['+91', '+93', '+355', '+213', '	+376']

export const success = {
  id: 1,
  title: 'Success',
  crossColor: colors.green1,
  icon: <Check />,
}

export const failure = {
  id: 2,
  title: 'Danger',
  crossColor: colors.red1,
  icon: <Error />,
}

export const warning = {
  id: 3,
  title: 'Warning',
  crossColor: colors.red1,
  icon: <Warning />,
}

export const globalError: string = 'Somthing Went Wrong'

export const mobileAppConfigTabData: ITab[] = [
  {
    id: 0,
    name: 'HEALTHBOX Medical Center',
    navigate: 'medicalcenter',
  },
  {
    id: 1,
    name: 'HEALTHBOX News',
    navigate: 'news',
  },
  {
    id: 2,
    name: 'HEALTHBOX Doctor',
    navigate: 'doctor',
  },
]

//mobile-config:proactNews
export const proactNewsDataArray: {
  _id: number
  title: string
  desc: string
}[] = [
  { _id: 0, title: 'r1', desc: 'healthbox' },
  { _id: 1, title: 'r2', desc: 'healthbox' },
  { _id: 2, title: 'r3', desc: 'healthbox' },
  { _id: 3, title: 'r4', desc: 'healthbox' },
  { _id: 4, title: 'r5', desc: 'healthbox' },
]
//mobile-config:proactDoctor
export const doctorArray: { id: number; name: string }[] = [
  { id: 0, name: 'Doctor 1' },
  { id: 1, name: 'Doctor 2' },
  { id: 2, name: 'Doctor 3' },
  { id: 3, name: 'Doctor 4' },
  { id: 4, name: 'Doctor 5' },
]
