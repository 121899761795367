import {
  IMPORT_COMPLAINS_UTILITY,
  IMPORT_DOCTORS_UTILITY,
  IMPORT_INSURANCE_UTILITY,
  IMPORT_MEDICINES_UTILITY,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  importCompliance,
  importDoctors,
  importInsurance,
  importMedicines,
} from "./importUtilityCrud";

export const importComplianceUtility = createAsyncThunkForSlice(
  IMPORT_COMPLAINS_UTILITY,
  importCompliance,
  {
    isToast: true,
  }
);

export const importInsuranceUtility = createAsyncThunkForSlice(
  IMPORT_INSURANCE_UTILITY,
  importInsurance,
  {
    isToast: true,
  }
);

export const importDoctorsUtility = createAsyncThunkForSlice(
  IMPORT_DOCTORS_UTILITY,
  importDoctors,
  {
    isToast: true,
  }
);
export const importMedicinesUtility = createAsyncThunkForSlice(
  IMPORT_MEDICINES_UTILITY,
  importMedicines,
  {
    isToast: true,
  }
);
