import { t } from "i18next";
import Button from "../button/Button";
import styles from "./samplCsv.module.scss";
import { FC } from "react";

interface ISampleCSV {
  onExport: any;
  disable?: boolean; 
  // style?: string
  style?: Record<string, string>; 
}

const SampleCSV: FC<ISampleCSV> = ({ onExport ,disable,style}) => {
  return (
    <Button
      title={"Sample CSV"}
      customClass={styles.csvBtn}
      type="button"
      handleClick={(e: any) => onExport(e.target.value)}
      disable={disable}
      style={style}
    />
  );
};

export default SampleCSV;
