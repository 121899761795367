import { FC } from 'react'
import profileIcon from '../../../../assets/images/headerProfileIcon.png'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import { LogoutIcon } from '../../svg-components'
import styles from './logoutModal.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../hooks/index'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useNavigate } from 'react-router-dom'
import { userLogout } from '../../../../redux/features/login/loginAsynActions'
interface ILogoutModal {
  logoutRef: any
}

const LogoutModal: FC<ILogoutModal> = ({ logoutRef }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { userData } = useAppSelector((state) => state.login)

  const handleLogout = () => {
    dispatch(userLogout(requestGenerator({})))
    navigate('/')
  }
  return (
    <>
      <div className={styles.mainContainer} ref={logoutRef}>
        <div className={styles.profileContainer}>
          <div className={styles.profileImageContainer}>
            <img className={styles.profileImage} src={profileIcon} alt="" />
          </div>
          <div className={styles.profileInfo}>
            <p className={styles.userNameStyle}>{userData?.name}</p>
            <p className={styles.userRoleStyle}>{userData?.role}</p>
          </div>
        </div>
        <Divider customClass={styles.dividerStyle} />

        <div>
          <span className={styles.logoutContainer}>
            <LogoutIcon fillColor={colors.black1} handleClick={handleLogout} />
            <p className={styles.logoutTextStyle} onClick={handleLogout}>
              Sign Out
            </p>
          </span>
        </div>
      </div>
    </>
  )
}

export default LogoutModal
