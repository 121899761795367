import styles from './searchDropDown.module.scss'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  DropDownArrowIcon,
  DropDownIcon,
  SearchButton,
} from '../svg-components'
import { trimValue } from '../../../utils/utils'

interface ISearchDropDown {
  setSearchValue?: any
  searchValue?: any
  dropDownData: Array<any>
  dropdownInitialState: string
  customClassContainer?: string
  customClassContent?: string
  dropwnInitialValueOption?: string
  handleChangeDropdown?: (e: React.SyntheticEvent<EventTarget>) => void
  searchPopData?: any
  setSearchPopData?: any
  packageFilter?: any
  setPackageFilter?: any
  setCurrentPage: any
  handleInputSearch?: any
  placeHolder?: any
  handleOnChangeInput?: any
}

const SearchDropDown: React.FunctionComponent<ISearchDropDown> = ({
  dropDownData,
  dropdownInitialState,
  customClassContainer,
  customClassContent,
  handleChangeDropdown,
  dropwnInitialValueOption,
  setSearchPopData,
  handleInputSearch,
  setPackageFilter,
  setCurrentPage,
  setSearchValue,
  searchValue,
  placeHolder,
  handleOnChangeInput,
}) => {
  const [state, setState] = useState<boolean>(false)
  const [value, setValue] = useState(dropdownInitialState)

  const dropdownRef = useRef<HTMLDivElement>(null)

  const checkIfOutsideClick = useCallback((event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setState(false) // Close the dropdown
    }
  }, [])
  useEffect(() => {
    document.addEventListener('mousedown', checkIfOutsideClick)
    return () => {
      document.removeEventListener('mousedown', checkIfOutsideClick)
    }
  }, [checkIfOutsideClick])
  // const [totalPage, setTotalPage] = useState<number>(0);

  // function for creating Page Index Array
  // const pageIndexArray = () => {
  //   let pageIndexOptions = [];
  //   for (let i = 1; i <= totalPage; i++) {
  //     pageIndexOptions.push(i);
  //   }
  //   return pageIndexOptions;
  // };
  // const pageIndexOptions = pageIndexArray();

  const handlevalue = (item: any) => {
    setValue(item.name)
    setState(false)
    setSearchValue('')
    setCurrentPage(1)

    setSearchPopData(item)
    setPackageFilter(item)
  }

  return (
    <>
      <div className={styles.mainContainer} ref={dropdownRef}>
        <div className={styles.globalFilterSection}>
          <input
            className={styles.globalSearchInput}
            type="text"
            value={searchValue}
            onFocus={() => {
              setSearchPopData({})
              setState(false)
            }}
            onChange={(e) => {
              trimValue(e)
              handleOnChangeInput(e)
              setSearchValue(e.target.value)
              setCurrentPage(1)
              if (e.target.value?.length > 0) {
                return setValue(value)
              }
              if (e.target.value?.length === 0) {
                // handleInputSearch();
                return setValue(dropdownInitialState)
              }

              // setValue(dropdownInitialState);
            }}
            placeholder={placeHolder ? placeHolder : 'Search'}
          />
          <SearchButton
            handleClick={() => {
              if (searchValue !== '') {
                handleInputSearch()
                return setValue(value)
              }
              if (searchValue?.length === 0) {
                // handleInputSearch();
                return setValue(dropdownInitialState)
              }
            }}
            customClass={styles.inputSearchButton}
          />
        </div>
        <div className={styles.dropDownMainContainer}>
          <p className={styles.packageTitle}>Package</p>
          <div
            className={styles.dropDownContainer}
            onChange={(e) => handleChangeDropdown && handleChangeDropdown(e)}
          >
            <div
              onClick={() => setState(!state)}
              className={[styles.dropDownContainer, customClassContainer].join(
                ' '
              )}
            >
              <span
                className={
                  value !== dropdownInitialState
                    ? styles.dropDownItem
                    : styles.placeholder
                }
              >
                {value}
              </span>

              <span className={styles.dropDownIcon}>
                {!state ? (
                  <DropDownIcon fillColor="#797979" />
                ) : (
                  <DropDownArrowIcon fillColor="#797979" />
                )}
              </span>
            </div>
            {state && (
              <div
                className={[styles.dropDownContent, customClassContent].join(
                  ' '
                )}
              >
                <p
                  className={styles.dropDownValue}
                  onClick={() => handlevalue({ name: 'All packages', _id: '' })}
                >
                  {/* Select package */}
                  {dropwnInitialValueOption}
                </p>

                {dropDownData &&
                  dropDownData
                    ?.filter((item: any) => item?.is_active)
                    .map((item, index) => {
                      return (
                        <p
                          className={styles.dropDownValue}
                          key={index}
                          onClick={() => handlevalue(item)}
                        >
                          {item.name}
                        </p>
                      )
                    })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchDropDown
